import PropTypes from "prop-types"
import React, { PureComponent } from "react"
import CoreLoading from "./loading"

// This prevents unnecessary updates on mount
const areRequiredPackagesLoaded = (requiredPackages) => {
  const locallyStoredPackages = localStorage.getItem("APP_LOADED_PACKAGES") || ""

  let loadedPackages = locallyStoredPackages.split(",").filter(Boolean)
  const _requiredPackages = [...new Set(requiredPackages)]

  return loadedPackages.filter((pckg) => _requiredPackages.indexOf(pckg) > -1).length === _requiredPackages.length
}

class PackageLoader extends PureComponent {
  state = {
    ready: !Array.isArray(this.props.packages) || areRequiredPackagesLoaded(this.props.packages),
  }

  componentDidMount() {
    const { ready } = this.state
    const { packages } = this.props

    if (!ready) {
      this.lazyLoad(packages).then((cb) => {
        if (typeof cb === "function") cb()
        else {
          this.setState({ ready: true })
        }
      })
    }
  }

  lazyLoad = (packages = []) => {
    if (Array.isArray(packages) === true) {
      return this.props.loadPackages(packages)
    } else {
      console.log(packages)
      throw new Error("Packages argument needs to be an Array")
    }
  }

  render() {
    const { ready } = this.state
    const { children, showLoader } = this.props
    if (ready === false) return showLoader ? <CoreLoading centered /> : null
    if (typeof children === "object") return children
    return children(this.lazyLoad, this.props.components)
  }
}

PackageLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

export default PackageLoader
