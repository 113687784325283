import React from "react"
import { render } from "react-dom"
import Core from "./external/core/Core"
import Account from "./external/core/Account"
import "array-flat-polyfill"

// if (process.env.NODE_ENV !== "production") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, { trackHooks: false });
// }
// if (process.env.NODE_ENV !== "production") {
//   const { whyDidYouUpdate } = require("why-did-you-update");
//   whyDidYouUpdate(React);
// }

if (window.matchMedia("(prefers-color-scheme: dark)").matches) import("./basicDarkStyle.css").then(() => {})
else import("./basicStyle.css").then(() => {})

const account = new Account()
function renderApp() {
  const urlSearchParams = new URL(window.location.href).searchParams

  const arrigoId = urlSearchParams.get("arrigoId")
  const code = urlSearchParams.get("code")

  if (arrigoId || code) {
    if (arrigoId === "1") {
      localStorage.setItem("arrigoId", true)
    } else {
      localStorage.removeItem("arrigoId")
    }

    if (code) {
      const locale = urlSearchParams.get("locale")
      if (locale) {
        localStorage.setItem("locale", locale)
      }

      localStorage.setItem("oauth", code)
      localStorage.removeItem("currentAccount")
      sessionStorage.removeItem("currentAccount")
      sessionStorage.removeItem("authToken")

      // Since BMS doesn't have accounts, clear the credentials cache.
      localStorage.removeItem("persist:session")
    }

    // ^--- We remove this value from local storage when showing the button in the ArrigoIdLogin.js-component
    window.location.href =
      window.location.origin + window.location.pathname + window.location.hash + (urlSearchParams.get("state") || "")

    return
  }
  render(
    <Core
      packages={["eos", "eos-navigation", "eos-network", "eos-notifications", "eos-users", "eos-collection"]}
      account={account}
    />,
    document.getElementById("root")
  )
}
sessionStorage.removeItem("refreshing")
sessionStorage.removeItem("maintenance")
sessionStorage.removeItem("reloadingPage")
renderApp()
