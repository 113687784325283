import React, { PureComponent } from "react"
import { mergeFunctions } from "./helpers"
import CoreLoading from "./loading"
import PackageLoader from "./PackageLoader"

const DEBUG = process.env.REACT_APP_DEBUG === "true"

const APP_VERSION = process.env.REACT_APP_VERSION

// This the Core of the application which is responsible for:
// Load packages; Invoke the Managers; Store imported Components

class Core extends PureComponent {
  // It is an Object where the key is the name of the component and the value
  // is an Array of two positions. The 1st is the component wrapped by
  // all Managers and the 2nd is the config object.
  Components = {}
  // Stores Wrapped React Components, this is the "Components" prop
  _components = {}
  // It is needed to store the original imported component because when we
  // need to compose again (a new package arrived), we need to start fresh.
  // Avoids Composer fn running more than oen time for same component instance.
  originalComponents = {}
  // Array of imported Managers
  managers = []
  // List of Strings of imported packages.
  // Useful to avoid fetching the same package twice
  packagesLoaded = []

  newerVersion = (oldVersion, newVersion) => {
    const [major1, minor1, patch1] = oldVersion.split(".").map((n) => parseInt(n))
    const [major2, minor2, patch2] = newVersion.split(".").map((n) => parseInt(n))
    return major1 < major2 || minor1 < minor2 || patch1 < patch2
  }

  // If the APP_VERSION, or `meta.json` version, is bumped, clean the localStorage.
  verifyVersion = async (currentVersion) => {
    try {
      if (currentVersion === null) currentVersion = "0.0.0"

      let newVersion = currentVersion
      const metaVersion = await fetch(`meta.json?t=${new Date().getTime()}`, {})
        .then((a) => a.json())
        .then((a) => a.version)
        .catch((err) => {
          console.error("Error fetching `meta.json` while trying to verify version.", err)
          return "0.0.0"
        })

      if (this.newerVersion(newVersion, APP_VERSION)) newVersion = APP_VERSION
      if (this.newerVersion(newVersion, metaVersion)) newVersion = metaVersion

      if (newVersion !== currentVersion) {
        // Persist special keys from localStorage and add them back after localStorage.clear
        const cypressLogin = localStorage.getItem("cypress-login")
        const arrigoId = localStorage.getItem("arrigoId")
        const oauth = localStorage.getItem("oauth")
        const locale = localStorage.getItem("locale")

        localStorage.clear()

        if (cypressLogin) {
          localStorage.setItem("cypress-login", cypressLogin)
        }

        if (arrigoId) {
          localStorage.setItem("arrigoId", arrigoId)
        }

        if (oauth) {
          localStorage.setItem("oauth", oauth)
        }

        if (locale) {
          localStorage.setItem("locale", locale)
        }

        localStorage.setItem("APP_VERSION", newVersion)
      }
    } catch (e) {
      console.error("Error verifying version.", e)
    }
  }

  // Provides the loadPackages fn to PackageLoader.
  // This is the pattern used so one Component can call a Core function
  wrapLoadPackages = (PackageLoader) => {
    const getProxy = this.getProxy
    const _loadPackages = this.loadPackages
    const components = this._components
    return class extends React.PureComponent {
      render() {
        return <PackageLoader {...this.props} loadPackages={_loadPackages} components={getProxy(components)} />
      }
    }
  }

  constructor(props) {
    super(props)
    this.verifyVersion(localStorage.getItem("APP_VERSION")).then(() => {
      this.loadPackages(props.packages).then(() => this.setState({ lastUpdate: Date.now() }))
      this._components["PackageLoader"] = this.wrapLoadPackages(PackageLoader)
    })

    /* Prevents gestures like pinch to zoom */
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault()
    })
  }

  // The Provider function is a pattern to wrap the whole App.
  // It provides a global feature, like the Store, the Theme and so on.
  Provider = (WrappedComponent) => {
    const Components = this._components

    return class ComponentWithComponents extends React.PureComponent {
      render() {
        return <WrappedComponent {...this.props} Components={Components} Loading={CoreLoading} />
      }
    }
  }

  getProxy = (components) => {
    const componentProxy = {
      get: (obj, prop) => {
        // typeof prop === "string" prevents crash on react-dev-tools
        if (prop && typeof prop === "string" && !obj[prop])
          console.log(
            `Component ${prop} is not yet loaded!
			Wrap the component in a
			<PackageLoader packages={ arrayOfPackageNames } />
				{(lazyLoad, components)=>{
					const { ${prop} } = components;
					<${prop} .../>
				}
			</PackageLoader>
			to use the component directly after loading.`
          )
        return obj[prop]
      },
    }
    return new Proxy(components, componentProxy)
  }
  // The Composer is a pattern to add functionality to a Component.
  Composer = (WrappedComponent) => {
    const Components = this.getProxy(this._components)
    return class ComponentWithComponents extends React.PureComponent {
      render() {
        return <WrappedComponent {...this.props} Components={Components} />
      }
    }
  }

  loadPackages = (packages = []) => {
    return new Promise(async (resolve, reject) => {
      if (DEBUG === true) {
        console.warn("Asked to load: " + packages.join(","))
      }

      localStorage.setItem("APP_LOADED_PACKAGES", [])
      // Preventing importing the same package twice.
      const packagesNeeded = packages.filter((name) => this.packagesLoaded.indexOf(name) === -1)

      // If the Promise returns undefined, it means nothing was loaded.
      if (packagesNeeded.length === 0) {
        return resolve()
      }
      if (DEBUG === true) {
        console.warn("Loaded: " + this.packagesLoaded.join(","))
        console.warn("Loading: " + packagesNeeded.join(","))
      }
      this.packagesLoaded = [...this.packagesLoaded, ...packagesNeeded]
      const modules = await this.fetch(packages)
      for (let i = 0; i < modules.length; i++) {
        await this.readModule(modules[i])
      }
      // modules.map(module => this.readModule(module));
      // Name of all imported Components
      this.composeComponents(Object.keys(this.originalComponents))
      // Updated _components, please check the top of the Class.
      Object.keys(this.Components).map((name) => (this._components[name] = this.Components[name][0]))

      // Return the an callback to update the Core. Is the responsibility of
      // the caller to decide when this is needed.
      if (DEBUG === true) console.warn("Loaded: " + packagesNeeded.join(","))

      return resolve(this.forceCoreUpdate)
    })
  }

  composeComponents(listOfComponents) {
    for (let n = 0; n < listOfComponents.length; n++) {
      const name = listOfComponents[n]
      // Please see the explanation at the top of the Class.
      this.Components[name][0] = this.originalComponents[name]
      for (let i = 0; i < this.managers.length; i++) {
        const Manager = this.managers[i]
        // If the Manager doesn't have a Composer function, skipped
        if (Manager.Composer === undefined) continue
        this.Components[name][0] = Manager.Composer(this.Components[name][0], this.Components[name][1])
      }

      // If skipCompose is true, the component doesn't need the Components prop.
      if (this.Components[name][1].skipCompose === true) continue
      this.Components[name][0] = this.Composer(this.Components[name][0], this.Components[name][1])
    }
  }

  readModule = async (module) => {
    if (module["Dependencies"] !== undefined) {
      await this.loadPackages(module["Dependencies"])
    }
    if (module["Managers"] !== undefined) {
      this.addManagers(module["Managers"])
    }
    if (module["Components"] !== undefined) {
      this.addComponents(module["Components"])
    }
    for (let i = 0; i < this.managers.length; i++) {
      const Manager = this.managers[i]
      if (typeof Manager.onModuleLoaded === "function") {
        Manager.onModuleLoaded(module)
      }
    }
  }

  addComponents(Components) {
    Object.keys(Components).map((name) => {
      const Component = Components[name]
      if (Array.isArray(Component)) {
        this.Components[name] = [Component[0], Component[1]]
        this.originalComponents[name] = Component[0]
      } else {
        this.Components[name] = [Component, {}]
        this.originalComponents[name] = Component
      }
      return name
    })
  }

  addManagers(Managers) {
    Object.keys(Managers).map((name) => {
      const Manager = Managers[name]
      // Put the Manager on the start of the Array. The first imported Manager
      // is the last one to be called. The order matters!
      this.managers.unshift(Manager)
      if (typeof Manager.Provider === "function") {
        this.Provider = mergeFunctions(this.Provider, Manager.Provider)
      }
      return name
    })
  }

  fetch = (list) => Promise.all(list.map((module) => import(`../packages/${module}`)))

  forceCoreUpdate = (cb) => {
    if (DEBUG === true) console.log("Forced update on Core")
    localStorage.setItem("APP_LOADED_PACKAGES", this.packagesLoaded)
    return this.setState({ lastUpdate: Date.now() }, () => typeof cb === "function" && cb())
  }

  render() {
    if (Object.keys(this.Components).length === 0) {
      return <CoreLoading centered />
    }
    const MyProvider = this.Provider(({ Components: { App } }) => (
      <App network={this.props.network} account={this.props.account} />
    ))

    return (
      <>
        <CoreLoading />
        <MyProvider />
      </>
    )
  }
}
export default Core
