class Account {
  callback
  currentSession

  constructor() {
    this.subscribe()
  }

  registerAccountChange(callback) {
    this.callback = callback
    this.currentSession = this.parseSession(localStorage.getItem("persist:session"))
  }

  parseSession(session) {
    try {
      return JSON.parse(session)
    } catch (e) {
      return null
    }
  }

  parseSessionValues(obj) {
    try {
      Object.keys(obj).forEach((k) => (obj[k] = JSON.parse(obj[k])))
      return obj
    } catch (err) {
      return null
    }
  }

  didAccountChange(oldSession, newSession) {
    const oldAccount = oldSession?.account?.toLowerCase()
    const newAccount = newSession?.account?.toLowerCase()

    //TODO: Maybe add support for list, longer than just the two recent accounts (?)

    if (oldAccount && newAccount && oldAccount !== newAccount) {
      this.onAccountChange({
        currentSession: this.parseSessionValues(this.currentSession),
        newSession: this.parseSessionValues(newSession),
      })
    }
  }

  onAccountChange(accountsList) {
    if (!this.callback) return
    this.callback(accountsList)
  }

  accountListener = (e) => {
    const oldSession = this.parseSession(e.oldValue)
    const newSession = this.parseSession(e.newValue)

    if (!oldSession || !newSession) return
    this.didAccountChange(this.parseSession(e.oldValue), this.parseSession(e.newValue))
  }

  subscribe() {
    // This was commented out because localStorage was overridden.
    // window.addEventListener("storage", this.accountListener)
  }

  unsubscribe() {
    // This was commented out because localStorage was overridden.
    // window.removeEventListener("storage", this.accountListener)
  }
}

export default Account
