import styled, { css } from "styled-components"

const StyledCoreLoading = styled.div`
  margin: auto;
  width: 50px;
  height: 100%;

  text-align: center;
  font-size: 10px;
  display: flex;
  vertical-align: center;
  align-items: center;

  /* used to center initial loading */
  ${(props) =>
    props.centered &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 99999999;
    `}

  svg {
    width: 35px;
    fill: #85919d;
  }

  .spinner {
    margin: auto;
    width: 50px;
    height: 30px;
    text-align: center;
    font-size: 10px;
    display: flex;
    justify-content: space-around;
  }

  .spinner > div {
    background-color: #85919d;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      -webkit-transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
`

export default StyledCoreLoading
