import React from "react"
import StyledCoreLoading from "./styles/StyledCoreLoading"

const CoreLoading = (centered) => (
  <StyledCoreLoading id="core-loading" centered={centered}>
    <div className="spinner">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
      <div className="rect5" />
    </div>
  </StyledCoreLoading>
)

export default CoreLoading
